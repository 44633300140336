import React, {useState} from 'react'
import {ErrorState, Heading3, SpinnerPage} from '../../Primitives'
import {useAlert} from 'react-alert'
import {playground} from '~/pages/Playground'
import {useHistory} from 'react-router-dom'
import {getGraphQLErrorMessage} from '~/util'
import {ApolloError} from '@apollo/client'
import {CheckYourEmail2} from '~/components/Primitives/CheckYourEmail2'
import {FormLayout2} from '~/components/Primitives/Forms/FormLayout2'
import styled from 'styled-components'
import {Column, Row} from '~/components/Primitives/Layout'

const Video = styled.video`
    max-width: 100%;
    max-height: 100%;
    height: auto;
`

export type Page = 'check-your-mail' | 'welcome' | 'add-your-people' | 'upload-photos' | 'download-app'

export type PageState = {
    errorState: ErrorState
    email?: string
    organisationIdStr?: string
    directoryName?: string
    code?: string
}

export const StripeFlow = ({
    page,
    setPage,
    pageState,
    setPageState,
    requestOtp,
    signInOtp,
    onComplete,
}: {
    page: Page
    setPage: (value: Page) => void
    pageState: PageState
    setPageState: (value: PageState) => void
    requestOtp: (emailAddress: string) => Promise<void>
    signInOtp: (otp: string, email: string) => Promise<boolean>
    onComplete: (directoryName: string) => Promise<string | undefined>
}) => {
    const [loading, setLoading] = useState<boolean>(false)
    const alert = useAlert()
    const history = useHistory()

    if (page === 'check-your-mail') {
        return (
            <CheckYourEmail2
                loading={loading}
                setLoading={setLoading}
                code={pageState.code || ''}
                setCode={(value) => setPageState({...pageState, code: value})}
                onNext={async () => {
                    if (!pageState.code || !pageState.email) return
                    setPageState({...pageState, errorState: {code: {visible: false, message: ''}}})
                    try {
                        const result = await signInOtp(pageState.code, pageState.email)
                        if (!result) {
                            setPageState({...pageState, errorState: {code: {visible: true, message: 'Invalid code'}}})
                        } else {
                            const organisationIdStr = await onComplete(pageState.directoryName || 'Untitled')
                            setPageState({...pageState, organisationIdStr})
                            setPage('welcome')
                        }
                    } catch (err) {
                        alert.error(getGraphQLErrorMessage(err as ApolloError))
                    }
                }}
                onDidntGetCode={async () => {
                    if (!pageState.email) return
                    await requestOtp(pageState.email)
                    alert.success('We have emailed you a new code.')
                }}
                errorState={pageState.errorState}
                setErrorState={(value) => setPageState({...pageState, errorState: value})}
            />
        )
    }

    if (page === 'welcome') {
        return (
            <FormLayout2
                heading="Welcome!"
                subHeading="Take a quick tour of the setup process to see how easy it is to create your directory. You'll start with a company template to help you get up and running fast."
                progress={2}
                loading={loading}
                setLoading={setLoading}
                onNext={async () => {
                    setPage('add-your-people')
                }}
            >
                <Video autoPlay={true} loop={true} muted={true}>
                    <source
                        type="video/mp4"
                        src="https://cdn.namesandfaces.com/assets/website/video/webapp/onboarding/2025_1_welcome.mp4"
                    />
                </Video>
            </FormLayout2>
        )
    }

    if (page === 'add-your-people') {
        return (
            <FormLayout2
                heading="Add your people"
                subHeading="Add your people and their details to the Data table. If you have existing information in a spreadsheet, simply copy and paste it across."
                progress={3}
                loading={loading}
                setLoading={setLoading}
                onNext={async () => {
                    setPage('upload-photos')
                }}
            >
                <Row
                    style={{
                        position: 'absolute',
                        overflow: 'hidden',
                        width: 'calc(min(879px, 100% - 81px))',
                        marginLeft: '-15px',
                    }}
                >
                    <img
                        alt=""
                        height="319px"
                        src="https://cdn.namesandfaces.com/assets/website/video/webapp/onboarding/2025_2_add_your_people.avif"
                    ></img>
                </Row>
                <Row height="319px"></Row>
            </FormLayout2>
        )
    }

    if (page === 'upload-photos') {
        return (
            <FormLayout2
                heading="Upload photos — or send photo requests"
                subHeading="Upload your photos and match them to the people in your directory or use the photo collection feature to request photos from your people."
                progress={4}
                loading={loading}
                setLoading={setLoading}
                onNext={async () => {
                    setPage('download-app')
                }}
            >
                <Row
                    style={{
                        position: 'absolute',
                        overflow: 'hidden',
                        width: 'calc(min(879px, 100% - 81px))',
                        marginLeft: '-15px',
                    }}
                >
                    <img
                        alt=""
                        height="636px"
                        src="https://cdn.namesandfaces.com/assets/website/video/webapp/onboarding/2025_3_upload_photos.avif"
                    ></img>
                </Row>
                <Row height="636px"></Row>
            </FormLayout2>
        )
    }

    if (page === 'download-app') {
        return (
            <FormLayout2
                heading="Get Names & Faces for your Phone"
                subHeading="The fastest Names & Faces experience is on your phone. Get it now and see your directory come to life in real time as you build it."
                progress={5}
                loading={loading}
                setLoading={setLoading}
                onNext={async () => {
                    pageState.organisationIdStr && history.push(`/${pageState.organisationIdStr}/admin`)
                }}
            >
                <div style={{textAlign: 'center', width: '100%'}}>
                    <span
                        style={{
                            position: 'absolute',
                            overflow: 'hidden',
                            height: '533px',
                            width: '262px',
                            borderRadius: '38px',
                        }}
                    >
                        <div style={{marginLeft: '-34px', marginTop: '-48px', width: '1163px'}}>
                            <Video autoPlay={true} loop={true} muted={true}>
                                <source
                                    type="video/mp4"
                                    src="https://cdn.namesandfaces.com/assets/website/video/webapp/onboarding/2025_1_welcome.mp4"
                                />
                            </Video>
                        </div>
                    </span>
                    <span
                        style={{
                            height: '533px',
                            width: 'calc(max(262px, min(310px, 100% - 269px)))',
                            display: 'inline-block',
                        }}
                    ></span>
                    <span
                        style={{width: 'calc(max(270px, 100% - 333px))', display: 'inline-block', verticalAlign: 'top'}}
                    >
                        <Row>
                            <Column grow={1}></Column>
                            <Column grow={0}>
                                <img
                                    alt=""
                                    height="200px"
                                    width="200px"
                                    src="https://cdn.namesandfaces.com/assets/website/video/webapp/onboarding/2025_4_qr.png"
                                ></img>
                            </Column>
                            <Column grow={1}></Column>
                        </Row>
                        <Column>
                            <Heading3 fontSize="15px" centerHorizontal>
                                Visit the App Store, Google Play, or scan with your phone to download instantly
                            </Heading3>
                            <Row height="20px"></Row>
                            <div style={{textAlign: 'center'}}>
                                <span style={{height: '10px', width: '10px', display: 'inline-block'}}></span>
                                <a href="https://apps.apple.com/us/app/names-faces/id949875464">
                                    <img
                                        width="177px"
                                        src="https://developer.apple.com/assets/elements/badges/download-on-the-app-store.svg"
                                        alt="Download on the App Store"
                                        style={{width: '177px', margin: '14px'}}
                                    ></img>
                                </a>
                                <span style={{height: '10px', width: '10px', display: 'inline-block'}}></span>
                                <a href="http://play.google.com/store/apps/details?id=za.co.polymorph.namesfaces">
                                    <img
                                        width="225px"
                                        src="https://play.google.com/intl/en_us/badges/images/generic/en_badge_web_generic.png"
                                        alt="Get it on Google Play"
                                    ></img>
                                </a>
                            </div>
                        </Column>
                    </span>
                </div>
            </FormLayout2>
        )
    }

    return <SpinnerPage></SpinnerPage>
}

playground.push({
    path: 'src/components/Onboarding/SelfBuild/StripeFlow.tsx',
    component: StripeFlow,
    props: {
        page: 'download-app',
        pageState: {
            formState: {},
            errorState: {},
        },
    },
    propOptions: {
        page: {
            get: (props: any) => JSON.stringify(props.page),
        },
        setPage: ({props, args}: {props: any; args: any[]}) => {
            return {...props, page: args[0]}
        },
        pageState: {
            get: (props: any) => JSON.stringify(props.pageState),
        },
        setPageState: ({props, args}: {props: any; args: any[]}) => ({...props, pageState: args[0]}),
        requestOtp: () => {},
        signInOtp: () => {},
        onComplete: () => {},
    },
})
