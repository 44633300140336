import styled from 'styled-components'
import {HideableCss} from './css'
import {HideableCssProps, PositionCssProps} from './types'
import {PositionCss} from './css'

export const Column = styled.div<
    {
        width?: string
        minWidth?: string
        maxWidth?: string
        height?: string
        minHeight?: string
        grow?: number
        shrink?: number
        overflow?: string
        overflowX?: string
        overflowY?: string
        backgroundColor?: string
        boxShadow?: string
        borderRadius?: string
        centerVertical?: boolean
        cursor?: string
    } & HideableCssProps &
        PositionCssProps
>`
    display: flex;
    flex-direction: column;
    justify-content: ${({centerVertical}) => (centerVertical ? 'center' : 'space-between')};
    ${({backgroundColor}) => (backgroundColor ? `background-color: ${backgroundColor};` : '')}
    ${({borderRadius}) => (borderRadius ? `border-radius: ${borderRadius};` : '')}
    ${({boxShadow}) => (boxShadow ? `box-shadow: ${boxShadow};` : '')}
    ${({width}) => (width ? `width: ${width};` : '')}
    ${({minWidth}) => (minWidth ? `min-width: ${minWidth};` : '')}
    ${({maxWidth}) => (maxWidth ? `max-width: ${maxWidth};` : '')}
    ${({height}) => (height ? `height: ${height};` : '')}
    ${({minHeight}) => (minHeight ? `min-height: ${minHeight};` : '')}
    ${({grow}) => (grow !== undefined ? `flex-grow: ${grow};` : '')}
    ${({shrink}) => (shrink !== undefined ? `flex-shrink: ${shrink};` : '')}
    ${({overflow}) => (overflow !== undefined ? `overflow: ${overflow};` : '')}
    ${({overflowX}) => (overflowX !== undefined ? `overflow-x: ${overflowX};` : '')}
    ${({overflowY}) => (overflowY !== undefined ? `overflow-y: ${overflowY};` : '')}
    ${({cursor}) => (cursor !== undefined ? `cursor: ${cursor};` : '')}
    ${HideableCss}
    ${PositionCss}
`
