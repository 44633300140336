import {Button} from 'nf-ui'
import React, {useEffect, useRef, useState} from 'react'
import {playground} from '~/pages/Playground'
import {Column, Row} from '../../Primitives/Layout'
import {EMAIL_REGEX, ErrorMessage, ErrorRows, ErrorState, Inter, hideError} from '../../Primitives'
import {TextInputElement, VisibleInput} from '../../Primitives/InputFields/VisibleInput'
import {FormLayout1} from '../../Primitives/Forms/FormLayout1'

export const SignUp = ({
    email,
    setEmail,
    directoryName,
    setDirectoryName,
    acceptedTerms,
    setAcceptedTerms,
    errorState,
    setErrorState,
    onNext,
}: {
    email: string
    setEmail: (value: string) => void
    directoryName: string
    setDirectoryName: (value: string) => void
    acceptedTerms: boolean
    setAcceptedTerms: (value: boolean) => void
    errorState: ErrorState
    setErrorState: (value: ErrorState) => void
    onNext: () => Promise<void>
}) => {
    const [loading, setLoading] = useState<boolean>(false)
    const inputRefs = [
        useRef<TextInputElement>(null),
        useRef<TextInputElement>(null),
        useRef<TextInputElement>(null),
        useRef<TextInputElement>(null),
    ]

    useEffect(() => {
        if (inputRefs[0].current?.offsetHeight) {
            setTimeout(() => inputRefs[0].current?.focus(), 10)
        } else if (inputRefs[2].current?.offsetHeight) {
            setTimeout(() => inputRefs[2].current?.focus(), 10)
        }

        return () => {}
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const validate = () => {
        const newState = {
            email: {visible: email === '' || !EMAIL_REGEX.test(email), message: 'Please enter a valid email address'},
            acceptedTerms: {
                visible: !acceptedTerms,
                message: 'You need to agree to the terms of service and privacy policy',
            },
        }
        const valid = !newState.email.visible && !newState.acceptedTerms.visible
        setErrorState({
            ...errorState,
            ...newState,
        })
        return valid
    }
    const onValidatedNext = async () => {
        if (!validate()) {
            return
        }
        setLoading(true)
        await onNext()
        setLoading(false)
    }
    return (
        <FormLayout1
            heading="Sign up"
            subHeading={`Sign up and create your own Names & Faces directory.`}
            progress={0}
            onNext={onValidatedNext}
        >
            <Column width="100%" deviceWidths={['Desktop', 'Tablet']}>
                <Row width="100%" alignLeft>
                    <Column>
                        <VisibleInput
                            ref={inputRefs[0]}
                            type="email"
                            width="329px"
                            placeholder="Email"
                            value={email}
                            hasError={errorState.email?.visible}
                            scrollMarginBottom={70}
                            onChange={(ev) => {
                                hideError('email', errorState, setErrorState)
                                setEmail(ev.target.value)
                            }}
                            onKeyPress={(ev) => ev.key === 'Enter' && validate() && inputRefs[1].current?.focus()}
                        ></VisibleInput>
                        <ErrorRows errorRecord={errorState.email}></ErrorRows>
                    </Column>
                </Row>
                <Row height="10px" />
                <Row width="100%" alignLeft>
                    <Column>
                        <VisibleInput
                            ref={inputRefs[1]}
                            type="text"
                            width="329px"
                            placeholder="Give your directory a name"
                            value={directoryName}
                            hasError={errorState.directoryName?.visible}
                            scrollMarginBottom={70}
                            onChange={(ev) => {
                                hideError('directoryName', errorState, setErrorState)
                                setDirectoryName(ev.target.value)
                            }}
                            onKeyPress={(ev) => ev.key === 'Enter' && onValidatedNext()}
                        ></VisibleInput>
                        <ErrorRows errorRecord={errorState.directoryName}></ErrorRows>
                    </Column>
                </Row>
                <Row height="10px" />
                <Row width="100%" alignLeft padding="10px">
                    <Inter fontSize="14px" fontWeight="400" color="#5c5c5c">
                        <label>
                            <input
                                type="checkbox"
                                checked={acceptedTerms}
                                onChange={() => setAcceptedTerms(!acceptedTerms)}
                            ></input>
                            &nbsp;&nbsp;I agree to the{' '}
                            <a
                                href="https://www.namesandfaces.com/policies/user-terms-of-service"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Terms of Service
                            </a>{' '}
                            and{' '}
                            <a
                                href="https://www.namesandfaces.com/policies/privacy-policy"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Privacy Policy
                            </a>
                        </label>
                    </Inter>
                </Row>
                {errorState.acceptedTerms?.visible && errorState.acceptedTerms?.message && (
                    <>
                        <Row>
                            <Column width="14px" grow={0} shrink={0}></Column>
                            <Column grow={0} shrink={1}>
                                <ErrorMessage>{errorState.acceptedTerms?.message || ''}</ErrorMessage>
                            </Column>
                            <Column width="14px" grow={1} shrink={0}></Column>
                        </Row>
                        <Row height="10px" />
                    </>
                )}
                <Row height="10px" />
                <Row width="100%" alignLeft>
                    <Button
                        style={{padding: '0px 24px', borderRadius: '5px', height: '48px'}}
                        onClick={onValidatedNext}
                        loading={loading}
                    >
                        Next
                    </Button>
                </Row>
            </Column>
            <Column width="100%" deviceWidths={['Phone', 'Small']}>
                <VisibleInput
                    ref={inputRefs[2]}
                    type="email"
                    maxWidth="422px"
                    placeholder="Email"
                    value={email}
                    hasError={errorState.email?.visible}
                    scrollMarginBottom={70}
                    onChange={(ev) => {
                        hideError('email', errorState, setErrorState)
                        setEmail(ev.target.value)
                    }}
                    onKeyPress={(ev) => ev.key === 'Enter' && validate() && inputRefs[3].current?.focus()}
                ></VisibleInput>
                {errorState.email?.visible && errorState.email?.message && (
                    <>
                        <Row height="10px" />
                        <Row>
                            <Column width="14px" grow={0} shrink={0}></Column>
                            <Column grow={0} shrink={1}>
                                <ErrorMessage>{errorState.email?.message || ''}</ErrorMessage>
                            </Column>
                            <Column width="14px" grow={1} shrink={0}></Column>
                        </Row>
                    </>
                )}
                <Row height="10px"></Row>
                <VisibleInput
                    ref={inputRefs[3]}
                    type="text"
                    maxWidth="422px"
                    placeholder="Give your directory a name"
                    value={directoryName}
                    hasError={errorState.directoryName?.visible}
                    scrollMarginBottom={70}
                    onChange={(ev) => {
                        hideError('directoryName', errorState, setErrorState)
                        setDirectoryName(ev.target.value)
                    }}
                    onKeyPress={(ev) => ev.key === 'Enter' && onValidatedNext()}
                ></VisibleInput>
                {errorState.directoryName?.visible && errorState.directoryName?.message && (
                    <>
                        <Row height="10px" />
                        <Row>
                            <Column width="14px" grow={0} shrink={0}></Column>
                            <Column grow={0} shrink={1}>
                                <ErrorMessage>{errorState.directoryName?.message || ''}</ErrorMessage>
                            </Column>
                            <Column width="14px" grow={1} shrink={0}></Column>
                        </Row>
                    </>
                )}
                <Row height="10px"></Row>

                <Row padding="10px">
                    <Inter fontSize="14px" fontWeight="400" color="#5c5c5c">
                        <label>
                            <input
                                type="checkbox"
                                checked={acceptedTerms}
                                onChange={() => setAcceptedTerms(!acceptedTerms)}
                            ></input>
                            &nbsp;&nbsp;I agree to the{' '}
                            <a
                                href="https://www.namesandfaces.com/policies/user-terms-of-service"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Terms of Service
                            </a>{' '}
                            and{' '}
                            <a
                                href="https://www.namesandfaces.com/policies/privacy-policy"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Privacy Policy
                            </a>
                        </label>
                    </Inter>
                </Row>
                {errorState.acceptedTerms?.visible && errorState.acceptedTerms?.message && (
                    <>
                        <Row>
                            <Column width="14px" grow={0} shrink={0}></Column>
                            <Column grow={0} shrink={1}>
                                <ErrorMessage>{errorState.acceptedTerms?.message || ''}</ErrorMessage>
                            </Column>
                            <Column width="14px" grow={1} shrink={0}></Column>
                        </Row>
                        <Row height="10px" />
                    </>
                )}
                <Row height="10px"></Row>
                <Button
                    style={{borderRadius: '5px', height: '48px', maxWidth: '450px', width: '100%'}}
                    onClick={onValidatedNext}
                    loading={loading}
                >
                    Next
                </Button>
                <Row height="20px"></Row>
            </Column>
        </FormLayout1>
    )
}

playground.push({
    path: 'src/components/Onboarding/SelfBuild/SignUp.tsx',
    component: SignUp,
    props: {email: '', directoryName: '', errorState: {}},
    propOptions: {
        email: {
            get: (props: any) => props.email || '',
        },
        setEmail: ({props, args}: {props: any; args: any[]}) => ({...props, email: args[0]}),
        directoryName: {
            get: (props: any) => props.directoryName || '',
        },
        setDirectoryName: ({props, args}: {props: any; args: any[]}) => ({...props, directoryName: args[0]}),
        errorState: {
            get: (props: any) => JSON.stringify(props.errorState),
        },
        setErrorState: ({props, args}: {props: any; args: any[]}) => ({...props, errorState: args[0]}),
        onNext: () => {},
    },
})
