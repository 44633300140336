import {AnalyticsContext, NFThemeProvider, Typography} from 'nf-ui'
import React, {Suspense, useEffect, useState} from 'react'
import {CookiesProvider} from 'react-cookie'
import {hot} from 'react-hot-loader/root'
import {Redirect, Route, Switch, useHistory} from 'react-router-dom'
import {QueryParamProvider, useQueryParam} from 'use-query-params'
import {CurrentOrganisation} from '~/components/CurrentOrganisationContext'
import {PageLoading} from '~/components/PageLoading'
import {Token} from '~/components/TokenContext'
import {NotFound} from '~/pages/NotFound'
import {AuthenticatedRoot} from './AuthenticatedRoot'
import {RouteLog} from './components/RouteLog'
import {useCurrentUser} from './components/useCurrentUser'
import {useIdentifyUser} from './components/useIdentifyUser'
import {SignoutURLProvider} from './components/useSignout'
import {useSystemAcessCheck} from './components/useSystemAcessCheck'
import {SystemAccessOption} from './globalTypes'
import {OrganisationIdRoot} from './OrganisationIdRoot'
import {OrganisationNoAccess} from './OrganisationNoAccess'
import {AcceptTerms} from './pages/AcceptTerms'
import {CollectAdditionalDataForm} from './pages/Adminland/Integrations/CollectAdditonalDataForm/CollectAdditionalDataForm'
import {ADPOnboarding} from './pages/ADPOnboarding'
import {ColorConstrast} from './pages/ColorContrast'
import {HandleOAuthCode} from './pages/HandleOAuthCode'
import {WelcomeAdp} from './pages/Onboarding/WelcomeAdp'
import {ForgotPassword} from './pages/Onboarding/ForgotPassword'
import {Login} from './pages/Onboarding/Login'
import {ADPLogin} from './pages/Onboarding/Login/ADPLogin'
import {SignInMagicCallback} from './pages/Onboarding/Login/SignInMagicCallback'
import {MobileSetPasswordSuccess} from './pages/Onboarding/MobileSetPasswordSuccess'
import {PhotoCollectionUpload} from './pages/PhotoCollectionUpload'
import {ResetPassword} from './pages/ResetPassword'
import {SetPassword} from './pages/SetPassword'
import SheetsAuth from './pages/SheetsAuth'
import {ViewedProfilesProvider} from './pages/Userland/Home/useViewedProfiles'
import {getGraphQLErrorMessage} from './util'
import {redirectToLegacy} from './util/legacy'
import {CreateAccount, CreateAccountStep} from './pages/Onboarding/CreateAccount'
import {VerifySignIn} from './pages/Onboarding/VerifySignIn'
import {VerificationEmailSent} from './pages/Onboarding/VerificationEmailSent'
import {WelcomeUser} from './pages/Onboarding/WelcomeUser'
import {useOpenAnalytics} from './components/useOpenAnalytics'
import {CreateDemoAccount} from './pages/Onboarding/CreateDemoAccount'
import {GoogleCalendarAuthorised} from './pages/GoogleCalendarAuthorised'
import {Playground} from './pages/Playground'
import {CommunityBuild} from './components/CommunityBuild'
import {SelfBuild} from './components/Onboarding/SelfBuild'
import {SuggestMobile} from './pages/Userland/SuggestMobile'
import {Stripe} from './components/Onboarding/Stripe'

const ManagementRoot = React.lazy(() => import('./pages/Management/ManagementRoot'))

const SelfBuildRoot: React.FC = () => {
    const {currentOrganisation, loading, error} = CurrentOrganisation.useContainer()
    const {me} = useCurrentUser()
    const {replace, location} = useHistory()

    useIdentifyUser()

    const showADPOnboarding = currentOrganisation?.showADPOnboarding
    useEffect(() => {
        if (showADPOnboarding && !location?.pathname?.endsWith('/management')) {
            replace('/onboarding/adp')
        }
    }, [showADPOnboarding, replace, location])

    // TODO: Find a better way to handle these states
    if (error) return <Typography.Heading>{error.message}</Typography.Heading>
    if (loading) return <PageLoading />

    if (!currentOrganisation) {
        return <CreateAccount initialStep={CreateAccountStep.DETAILS} />
    }

    if (me && !me.hasAcceptedTerms) {
        return <AcceptTerms />
    }

    return (
        <NFThemeProvider>
            <ViewedProfilesProvider>
                <Switch>
                    <Route exact path="/">
                        <AuthenticatedRoot />
                    </Route>
                    <Route exact path="/onboarding/adp">
                        {showADPOnboarding ? <ADPOnboarding /> : <Redirect to={`/${currentOrganisation.idStr}`} />}
                    </Route>
                    <Route exact path="/sheetsauth">
                        <SheetsAuth />
                    </Route>
                    <Route exact path="/googleCalendarAuthorised">
                        <GoogleCalendarAuthorised />
                    </Route>
                    {me?.isSuperAdmin && (
                        <Route path="/management">
                            <Suspense fallback={<PageLoading />}>
                                <ManagementRoot />
                            </Suspense>
                        </Route>
                    )}
                    <Route path="/:organisationId">
                        {({match}) => (
                            <OrganisationIdRoot organisationId={match!.params.organisationId} userIdStr={me?.idStr} />
                        )}
                    </Route>
                    <Route default>
                        <NotFound />
                    </Route>
                </Switch>
            </ViewedProfilesProvider>
        </NFThemeProvider>
    )
}

const RedirectToLegacy: React.FC = () => {
    useEffect(() => {
        //You can move this function to the first argument of useEffect but then you have to worry about what it returns
        redirectToLegacy()
    }, [])

    return <PageLoading />
}

const useLoginRedirect = () => {
    const [userId] = useQueryParam<string>('userId')

    const queryParams = userId ? `?userId=${userId}` : ''

    return `/login${queryParams}`
}

const RootApp: React.FC = () => {
    const {token} = Token.useContainer()
    const {loading, error, systemAccessCheck} = useSystemAcessCheck()
    const {me} = useCurrentUser()
    const {trackEvent} = useOpenAnalytics()
    const loginLink = useLoginRedirect()

    if (!token) {
        trackEvent('access', 'unauthenticated')
        return <Redirect to={loginLink} />
    }

    if (error) return <Typography.Heading>{getGraphQLErrorMessage(error)}</Typography.Heading>
    if (!systemAccessCheck || loading) return <PageLoading />

    if (systemAccessCheck.includes(SystemAccessOption.SELF_BUILD) || me?.isSuperAdmin) {
        return (
            <CurrentOrganisation.Provider>
                <SignoutURLProvider>
                    <SelfBuildRoot />
                </SignoutURLProvider>
            </CurrentOrganisation.Provider>
        )
    } else if (systemAccessCheck.includes(SystemAccessOption.LEGACY)) {
        return <RedirectToLegacy />
    } else {
        return <OrganisationNoAccess />
    }
}

const App: React.FC = () => {
    const [suggestMobileDismissed, setSuggestMobileDismissed] = useState(false)

    return (
        <CookiesProvider>
            <QueryParamProvider ReactRouterRoute={Route}>
                <AnalyticsContext.Provider value={{platform: 'Web'}}>
                    <RouteLog>
                        <Switch>
                            <Route path="/login/adp">
                                <ADPLogin />
                            </Route>
                            <Route path="/login">
                                <SuggestMobile
                                    dismissed={suggestMobileDismissed}
                                    setDismissed={setSuggestMobileDismissed}
                                >
                                    <Login />
                                </SuggestMobile>
                            </Route>
                            <Route path="/login_magic">
                                <SignInMagicCallback />
                            </Route>
                            <Route path="/welcome_adp">
                                <WelcomeAdp />
                            </Route>
                            <Route path="/welcome">
                                <WelcomeUser />
                            </Route>
                            <Route path="/oauth/callback">
                                <HandleOAuthCode />
                            </Route>
                            <Route path="/create_account">
                                <CreateAccount />
                            </Route>
                            <Route path="/demo">
                                <CreateDemoAccount />
                            </Route>
                            <Route path="/forgotpassword">
                                <ForgotPassword />
                            </Route>
                            {/* The next two routes are old urls for setting your password. */}
                            <Route path="/password/reset">
                                <ForgotPassword />
                            </Route>
                            <Route path="/password/email/:type/:email">
                                <ForgotPassword />
                            </Route>
                            <Route exact path="/color">
                                <ColorConstrast />
                            </Route>
                            <Route path="/verifyAccount">
                                <VerifySignIn />
                            </Route>
                            <Route path="/verification_email">
                                <VerificationEmailSent />
                            </Route>
                            {/* TODO: I think this route will also need a * at the end */}
                            <Route exact path="/collection/:token">
                                {({match}) => <PhotoCollectionUpload token={match!.params.token} />}
                            </Route>
                            <Route exact path="/data_collection/:token*">
                                {({match}) => <CollectAdditionalDataForm token={match!.params.token} />}
                            </Route>
                            <Route exact path="/reset_password/:token*">
                                {({match}) => <ResetPassword token={match!.params.token} />}
                            </Route>
                            <Route path="/setpassword/:token">
                                {({match}) => <SetPassword token={match!.params.token} />}
                            </Route>
                            <Route path="/mobilesetpasswordsuccess">
                                <MobileSetPasswordSuccess />
                            </Route>
                            <Route path="/playground">
                                <Playground />
                            </Route>
                            <Route path="/community_build/:formId">
                                {({match}) => <CommunityBuild formIdStr={match!.params.formId} />}
                            </Route>
                            <Route path="/create_directory/:customerId">
                                {({match}) => <Stripe customerId={match!.params.customerId} />}
                            </Route>
                            <Route path="/create_directory">
                                <SelfBuild />
                            </Route>
                            <Route default path="/">
                                <SuggestMobile
                                    dismissed={suggestMobileDismissed}
                                    setDismissed={setSuggestMobileDismissed}
                                >
                                    <RootApp />
                                </SuggestMobile>
                            </Route>
                        </Switch>
                    </RouteLog>
                </AnalyticsContext.Provider>
            </QueryParamProvider>
        </CookiesProvider>
    )
}

export default hot(App)
