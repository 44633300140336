import React from 'react'
import {Column, Row} from './Layout'

export const ProgressBars1 = ({progress, max}: {progress?: number | string; max: number}) => {
    const progressInt = typeof progress === 'string' ? parseInt(progress) || 0 : progress
    return (
        <Row height="6px" width="100%">
            {[1, 2, 3, 4, 5].slice(0, max).map((step) => (
                <Row key={step} grow={1} shrink={1}>
                    {step > 1 ? <Column grow={0} width="6px" /> : null}
                    <Column
                        grow={1}
                        backgroundColor={(progressInt || 1) >= step ? 'rgb(60, 142, 255)' : 'rgb(235, 235, 235)'}
                    />
                </Row>
            ))}
        </Row>
    )
}

export const ProgressBars2 = ({progress}: {progress?: number | string}) => {
    const progressInt = typeof progress === 'string' ? parseInt(progress) || 0 : progress
    return (
        <Row>
            <Column
                width="16px"
                height="16px"
                borderRadius="8px"
                backgroundColor={(progressInt || 1) === 1 ? 'rgb(77, 77, 77)' : 'rgb(235, 235, 235)'}
            />
            <Column width="6px" />
            <Column
                width="16px"
                height="16px"
                borderRadius="8px"
                backgroundColor={(progressInt || 1) === 2 ? 'rgb(77, 77, 77)' : 'rgb(235, 235, 235)'}
            />
            <Column width="6px" />
            <Column
                width="16px"
                height="16px"
                borderRadius="8px"
                backgroundColor={(progressInt || 1) === 3 ? 'rgb(77, 77, 77)' : 'rgb(235, 235, 235)'}
            />
            <Column width="6px" />
            <Column
                width="16px"
                height="16px"
                borderRadius="8px"
                backgroundColor={(progressInt || 1) === 4 ? 'rgb(77, 77, 77)' : 'rgb(235, 235, 235)'}
            />
            <Column width="6px" />
            <Column
                width="16px"
                height="16px"
                borderRadius="8px"
                backgroundColor={(progressInt || 1) === 5 ? 'rgb(77, 77, 77)' : 'rgb(235, 235, 235)'}
            />
        </Row>
    )
}
