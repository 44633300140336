import React, {useEffect, useState} from 'react'
import {Column, Row} from '../Layout'
import {Heading3} from '..'

export const Question = ({
    heading,
    children,
    bottomBorder,
}: {
    heading: string
    children: string | string[] | JSX.Element[]
    bottomBorder?: boolean
}) => {
    const [collapsed, setCollapsed] = useState<boolean>(true)
    const bodyRef = React.createRef<HTMLDivElement>()

    useEffect(() => {
        if (!collapsed && bottomBorder === false) {
            bodyRef.current?.scrollIntoView()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [collapsed])

    return (
        <>
            <Row height="30px"></Row>
            <Row ref={bodyRef}>
                {collapsed ? (
                    <Column
                        grow={0}
                        shrink={0}
                        style={{fontSize: '25px'}}
                        cursor="pointer"
                        onClick={() => setCollapsed(false)}
                    >
                        +
                    </Column>
                ) : (
                    <Column
                        grow={0}
                        shrink={0}
                        style={{fontSize: '40px', lineHeight: '20px'}}
                        cursor="pointer"
                        onClick={() => setCollapsed(true)}
                        width="14px"
                    >
                        -
                    </Column>
                )}
                <Column grow={0} shrink={0} width="15px"></Column>
                <Column grow={1} shrink={1}>
                    <Row cursor="pointer">
                        <Heading3
                            color="black"
                            onClick={() => setCollapsed(!collapsed)}
                            deviceWidths={['Desktop', 'Tablet']}
                        >
                            {heading}
                        </Heading3>
                        <Heading3
                            color="black"
                            fontSize="18px"
                            onClick={() => setCollapsed(!collapsed)}
                            deviceWidths={['Phone', 'Small']}
                        >
                            {heading}
                        </Heading3>
                    </Row>
                    {!collapsed ? (
                        <>
                            <Row height="20px"></Row>
                            <Row>
                                <Heading3 maxWidth="690px" deviceWidths={['Desktop', 'Tablet']}>
                                    {children}
                                </Heading3>
                                <Heading3 maxWidth="690px" fontSize="18px" deviceWidths={['Phone', 'Small']}>
                                    {children}
                                </Heading3>
                            </Row>
                        </>
                    ) : (
                        <></>
                    )}
                </Column>
                <Column grow={1} shrink={1}></Column>
            </Row>
            <Row height="30px" style={{borderBottom: bottomBorder === false ? '' : 'solid 1px rgba(0,0,0,0.1)'}}></Row>
        </>
    )
}
