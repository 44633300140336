import React, {forwardRef, useEffect, useImperativeHandle, useRef, useState} from 'react'
import {playground} from '~/pages/Playground'
import {Column, Row, scrollIfNeeded} from '../Primitives/Layout'

import {FormState} from '../Primitives/Forms/FormState'
import {AddAnotherParentButton, EMAIL_REGEX, ErrorRows, ErrorState, FieldSubText, hideError} from '../Primitives'
import {StyledInput, TextInputElement, VisibleInput} from '../Primitives/InputFields/VisibleInput'
import {Form} from './Form'

type ParentBlockProps = {
    index: number
    formState: FormState
    setFormState: (value: FormState) => void
    errorState: ErrorState
    setErrorState: (value: ErrorState) => void
    onEnterPressed: () => void
}

const ParentBlock = forwardRef<TextInputElement, ParentBlockProps>(
    ({index, formState, setFormState, errorState, setErrorState, onEnterPressed}: ParentBlockProps, ref) => {
        const inputRefs = [
            useRef<TextInputElement>(null),
            useRef<TextInputElement>(null),
            useRef<TextInputElement>(null),
            useRef<TextInputElement>(null),
            useRef<TextInputElement>(null),
        ]
        useImperativeHandle<TextInputElement | null, TextInputElement | null>(ref, () => inputRefs[0].current)

        return (
            <Column>
                <Column onClick={() => inputRefs[0].current?.focus()}>
                    <StyledInput
                        type="text"
                        value={`Parent ${index + 1}${index % 2 === 0 ? '*' : ''}`}
                        variant="heading"
                        disabled
                    ></StyledInput>
                </Column>

                <Row height="10px"></Row>
                <Row>
                    <Column>
                        <VisibleInput
                            ref={inputRefs[0]}
                            type="text"
                            maxWidth="191px"
                            placeholder={`First Name${index % 2 === 0 ? '*' : ''}`}
                            value={formState[`Parent ${index + 1} First Name`] || ''}
                            scrollMarginBottom={110}
                            onChange={(ev) => {
                                hideError(`Parent ${index + 1} First Name`, errorState, setErrorState)
                                setFormState({
                                    ...formState,
                                    ...Object.fromEntries([[`Parent ${index + 1} First Name`, ev.target.value]]),
                                })
                            }}
                            onKeyPress={(ev) => {
                                if (ev.key === 'Enter') {
                                    inputRefs[1].current?.focus()
                                }
                            }}
                        ></VisibleInput>
                        <ErrorRows errorRecord={errorState[`Parent ${index + 1} First Name`]}></ErrorRows>
                    </Column>
                    <Column width="10px"></Column>
                    <Column>
                        <VisibleInput
                            ref={inputRefs[1]}
                            type="text"
                            maxWidth="191px"
                            placeholder={`Last Name${index % 2 === 0 ? '*' : ''}`}
                            value={formState[`Parent ${index + 1} Last Name`] || ''}
                            scrollMarginBottom={110}
                            onChange={(ev) => {
                                hideError(`Parent ${index + 1} Last Name`, errorState, setErrorState)
                                setFormState({
                                    ...formState,
                                    ...Object.fromEntries([[`Parent ${index + 1} Last Name`, ev.target.value]]),
                                })
                            }}
                            onKeyPress={(ev) => {
                                if (ev.key === 'Enter') {
                                    inputRefs[2].current?.focus()
                                }
                            }}
                        ></VisibleInput>
                        <ErrorRows errorRecord={errorState[`Parent ${index + 1} Last Name`]}></ErrorRows>
                    </Column>
                </Row>
                <Row height="10px"></Row>

                <Column>
                    <VisibleInput
                        ref={inputRefs[2]}
                        type="email"
                        maxWidth="422px"
                        placeholder={`Email address${index % 2 === 0 ? '*' : ''}`}
                        value={formState[`Parent ${index + 1} Email Address`] || ''}
                        scrollMarginBottom={110}
                        onChange={(ev) => {
                            hideError(`Parent ${index + 1} Email Address`, errorState, setErrorState)
                            setFormState({
                                ...formState,
                                ...Object.fromEntries([[`Parent ${index + 1} Email Address`, ev.target.value]]),
                            })
                        }}
                        onKeyPress={(ev) => {
                            if (ev.key === 'Enter') {
                                inputRefs[3].current?.focus()
                            }
                        }}
                    ></VisibleInput>
                    <ErrorRows errorRecord={errorState[`Parent ${index + 1} Email Address`]}></ErrorRows>
                </Column>
            </Column>
        )
    },
)

export const ParentDetails = ({
    directoryName,
    formState,
    setFormState,
    errorState,
    setErrorState,
    onNext,
    onBack,
}: {
    directoryName: string
    formState: FormState
    setFormState: (value: FormState) => void
    errorState: ErrorState
    setErrorState: (value: ErrorState) => void
    onNext: () => Promise<void>
    onBack: () => void
}) => {
    const blockHasValue = (index: number) =>
        ['First Name', 'Last Name', 'Email Address'].some((field) => formState[`Parent ${index + 1} ${field}`])

    const validateParentBlock = (index: number): ErrorState => {
        const firstNameField =
            !formState[`Parent ${index + 1} First Name`] && (index === 0 || blockHasValue(index))
                ? {
                      ...Object.fromEntries([
                          [`Parent ${index + 1} First Name`, {visible: true, message: 'Please enter a first name'}],
                      ]),
                  }
                : {}

        const lastNameField =
            !formState[`Parent ${index + 1} Last Name`] && (index === 0 || blockHasValue(index))
                ? {
                      ...Object.fromEntries([
                          [`Parent ${index + 1} Last Name`, {visible: true, message: 'Please enter a last name'}],
                      ]),
                  }
                : {}

        const emailField =
            (formState[`Parent ${index + 1} Email Address`] &&
                !EMAIL_REGEX.test(formState[`Parent ${index + 1} Email Address`])) ||
            (!formState[`Parent ${index + 1} Email Address`] && index === 0)
                ? {
                      ...Object.fromEntries([
                          [
                              `Parent ${index + 1} Email Address`,
                              {visible: true, message: 'Please enter a valid email address'},
                          ],
                      ]),
                  }
                : {}

        return {...firstNameField, ...lastNameField, ...emailField}
    }

    const [visibleParentBlocks, setVisibleParentBlocks] = useState<number>(
        blockHasValue(3) ? 4 : blockHasValue(2) ? 3 : 2,
    )

    const parentBlocks = [
        useRef<TextInputElement>(null),
        useRef<TextInputElement>(null),
        useRef<TextInputElement>(null),
        useRef<TextInputElement>(null),
    ]

    const validateAndNext = async () => {
        const result = parentBlocks
            .slice(0, visibleParentBlocks)
            .reduce<ErrorState>((prev, ref, index) => ({...prev, ...validateParentBlock(index)}), {})

        setErrorState(result)
        if (Object.values(result).some((value) => value?.visible)) {
            return
        }

        await onNext()
    }

    useEffect(() => {
        parentBlocks[0].current?.focus()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [parentBlocks[0]])

    useEffect(() => {
        if (visibleParentBlocks > 2) {
            scrollIfNeeded(parentBlocks[3].current, {
                startTimeoutMilliseconds: 300,
                finishTimeoutMilliseconds: 1200,
                align: 'center',
                onScrollComplete: () => {
                    parentBlocks[2].current?.focus()
                },
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [visibleParentBlocks])

    return (
        <Form
            directoryName={directoryName}
            heading="Parent Details"
            subHeading="Fill in parent or guardian details below. Email addresses are required for access but will not be shared."
            progress={2}
            onNext={validateAndNext}
            onBack={onBack}
        >
            <Column>
                {parentBlocks.map((ref, index) => (
                    <Column key={index}>
                        {index === 0 && (
                            <StyledInput
                                type="text"
                                value="Household 1"
                                variant="heading-grey"
                                disabled
                                padding="0px 0px 20px"
                            ></StyledInput>
                        )}
                        {index === 2 && visibleParentBlocks <= 2 && (
                            <Column>
                                <AddAnotherParentButton onClick={() => setVisibleParentBlocks(4)}>
                                    Add another household?
                                </AddAnotherParentButton>
                                <Row height="10px"></Row>
                                <Row maxWidth="450px">
                                    <FieldSubText>
                                        Another household allows you to add two more parents and an additional family
                                        photo. Entering the email address of a parent in Household 2 will allow them to
                                        upload a second photo at a later stage.
                                    </FieldSubText>
                                </Row>
                            </Column>
                        )}
                        {index === 2 && visibleParentBlocks > 2 && (
                            <Column>
                                <Row>
                                    <StyledInput
                                        type="text"
                                        value="Household 2"
                                        variant="heading-grey"
                                        disabled
                                        padding="0px"
                                    ></StyledInput>
                                    <AddAnotherParentButton onClick={() => setVisibleParentBlocks(2)}>
                                        Remove
                                    </AddAnotherParentButton>
                                </Row>
                                <Row height="10px"></Row>
                                <Row maxWidth="450px">
                                    <FieldSubText>
                                        Another household allows you to add two more parents and an additional family
                                        photo. Entering the email address of a parent in Household 2 will allow them to
                                        upload a second photo at a later stage.
                                    </FieldSubText>
                                </Row>
                                <Row height="32px"></Row>
                            </Column>
                        )}
                        {index < visibleParentBlocks && (
                            <>
                                <ParentBlock
                                    ref={ref}
                                    index={index}
                                    formState={formState}
                                    setFormState={setFormState}
                                    errorState={errorState}
                                    setErrorState={setErrorState}
                                    onEnterPressed={() => {
                                        if (index + 1 < visibleParentBlocks) {
                                            parentBlocks[index + 1].current?.focus()
                                        } else {
                                            validateAndNext()
                                        }
                                    }}
                                ></ParentBlock>
                                {index < 3 && <Row height="32px"></Row>}
                            </>
                        )}
                    </Column>
                ))}
            </Column>
        </Form>
    )
}

playground.push({
    path: 'src/components/CommunityBuild/ParentDetails.tsx',
    component: ParentDetails,
    props: {
        directoryName: 'Wetpups Grade 3',
        formState: {},
        errorState: {},
    },
    propOptions: {
        formState: {
            get: (props: any) => JSON.stringify(props.formState),
        },
        setFormState: ({props, args}: {props: any; args: any[]}) => ({...props, formState: args[0]}),
        errorState: {
            get: (props: any) => JSON.stringify(props.errorState),
        },
        setErrorState: ({props, args}: {props: any; args: any[]}) => ({...props, errorState: args[0]}),
        onNext: () => {},
        onBack: () => {},
    },
})
