import React, {useState} from 'react'
import {useMutationRequestSelfBuildUserOtp, useMutationCreateSelfBuildDirectory} from '~/apollo/queries/SelfBuild'
import {Token} from '../../TokenContext'
import {useMutationSignInOtp} from '~/apollo/queries/SignInOtp'
import {SelfBuildFlow, Page, PageState} from './SelfBuildFlow'

export const SelfBuild = () => {
    const [page, setPage] = useState<Page>('sign-up')
    const [pageState, setPageState] = useState<PageState>({
        errorState: {},
    })

    const [, callRequestOtp] = useMutationRequestSelfBuildUserOtp()
    const [, callSignInOtp] = useMutationSignInOtp()
    const [, callCreateSelfBuildDirectory] = useMutationCreateSelfBuildDirectory()
    const {setToken} = Token.useContainer()

    return (
        <SelfBuildFlow
            page={page}
            setPage={setPage}
            pageState={pageState}
            setPageState={setPageState}
            requestOtp={async (email: string) => {
                await callRequestOtp({email})
            }}
            signInOtp={async (otp: string, email: string) => {
                const result = await callSignInOtp({otp, email})
                result && setToken(result)
                return !!result
            }}
            onComplete={async (directoryName: string) => {
                const result = await callCreateSelfBuildDirectory({
                    organisationName: directoryName,
                    organisationToClone: 'Self Build Template',
                })
                return result.data?.createSelfBuildDirectory
            }}
        />
    )
}
