import React, {useEffect, useRef} from 'react'
import {playground} from '~/pages/Playground'
import {Column, Row} from '../Primitives/Layout'

import {FormFieldConfig, FormState} from '../Primitives/Forms/FormState'
import {Form} from './Form'
import {ErrorRows, ErrorState, FieldSubText, hideError} from '../Primitives'
import {DatePicker} from '../Primitives/InputFields/DatePicker'
import {SingleSelectInput} from '../Primitives/InputFields/SingleSelectInput'
import {sortBy} from 'lodash'
import {formatDateYYYYMMDD} from '~/util/date'
import {TextInputElement, VisibleInput} from '../Primitives/InputFields/VisibleInput'

export const PupilDetails = ({
    directoryName,
    formFields,
    formState,
    setFormState,
    errorState,
    setErrorState,
    onNext,
    onBack,
}: {
    directoryName: string
    formFields: FormFieldConfig[]
    formState: FormState
    setFormState: (value: FormState) => void
    errorState: ErrorState
    setErrorState: (value: ErrorState) => void
    onNext: () => Promise<void>
    onBack: () => void
}) => {
    const inputRefs = [
        useRef<TextInputElement>(null),
        useRef<TextInputElement>(null),
        useRef<TextInputElement>(null),
        useRef<TextInputElement>(null),
        useRef<TextInputElement>(null),
        useRef<TextInputElement>(null),
    ]

    useEffect(() => {
        inputRefs[0].current?.focus()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inputRefs[0]])

    const validateAndNext = async () => {
        const requiredFields = Object.fromEntries(
            [
                ['Student First Name', 'a first name'],
                ['Student Last Name', 'a last name'],
                ['Grade', 'a grade'],
                ['Class', 'a class'],
                ['Birthday', 'a birthday'],
            ]
                .filter(([key]) => !formState[key])
                .map(([key, name]) => [key, {visible: true, message: `Please enter ${name}`}]),
        )

        const birthdayInPast =
            formState.Birthday && new Date(formState.Birthday) > new Date(new Date().valueOf() - 86400000)
                ? {Birthday: {visible: true, message: 'Birthday must be a date in the past'}}
                : {}

        const result = {...requiredFields, ...birthdayInPast}

        setErrorState(result)
        if (Object.values(result).some((value) => value?.visible)) {
            return
        }

        await onNext()
    }

    return (
        <Form
            directoryName={directoryName}
            heading="Student Details"
            subHeading="Complete your child's details below"
            progress={1}
            onNext={validateAndNext}
            onBack={onBack}
        >
            <Column>
                <Row>
                    <Column>
                        <VisibleInput
                            ref={inputRefs[0]}
                            type="text"
                            maxWidth="152px"
                            placeholder="Student First Name"
                            value={formState['Student First Name'] || ''}
                            scrollMarginBottom={110}
                            onChange={(ev) => {
                                hideError('Student First Name', errorState, setErrorState)
                                const newState = {...formState}
                                newState['Student First Name'] = ev.target.value
                                setFormState(newState)
                            }}
                            onKeyUp={(ev) => {
                                if (ev.key === 'Enter') {
                                    inputRefs[1].current?.focus()
                                }
                            }}
                        ></VisibleInput>
                        <ErrorRows errorRecord={errorState['Student First Name']}></ErrorRows>
                    </Column>
                    <Column width="10px"></Column>
                    <Column>
                        <VisibleInput
                            ref={inputRefs[1]}
                            type="text"
                            maxWidth="230px"
                            placeholder="Student Last Name"
                            value={formState['Student Last Name'] || ''}
                            scrollMarginBottom={110}
                            onChange={(ev) => {
                                hideError('Student Last Name', errorState, setErrorState)
                                const newState = {...formState}
                                newState['Student Last Name'] = ev.target.value
                                setFormState(newState)
                            }}
                            onKeyUp={(ev) => {
                                if (ev.key === 'Enter') {
                                    inputRefs[2].current?.focus()
                                }
                            }}
                        ></VisibleInput>
                        <ErrorRows errorRecord={errorState['Student Last Name']}></ErrorRows>
                    </Column>
                </Row>
                <Row height="10px"></Row>
                <Column>
                    <SingleSelectInput
                        ref={inputRefs[2]}
                        maxWidth="422px"
                        placeholder="Grade"
                        defaultValue={formState.Grade || ''}
                        availableValues={formFields.find((field) => field.name === 'Grade')?.availableValues || []}
                        freeText={formFields
                            .find((field) => field.name === 'Class')
                            ?.subType?.split(';')
                            ?.includes('freeText')}
                        onChange={(value: string) => {
                            hideError('Grade', errorState, setErrorState)
                            setFormState({...formState, Grade: value})
                        }}
                        onDoneEditing={() => inputRefs[3].current?.focus()}
                    ></SingleSelectInput>
                    <ErrorRows errorRecord={errorState.Grade}></ErrorRows>
                </Column>
                <Row height="10px"></Row>
                <Column>
                    <SingleSelectInput
                        ref={inputRefs[3]}
                        maxWidth="422px"
                        placeholder="Class"
                        defaultValue={formState.Class || ''}
                        availableValues={sortBy(
                            formFields.find((field) => field.name === 'Class')?.availableValues || [],
                            (value) => value,
                        )}
                        freeText={formFields
                            .find((field) => field.name === 'Class')
                            ?.subType?.split(';')
                            ?.includes('freeText')}
                        onChange={(value: string) => {
                            hideError('Class', errorState, setErrorState)
                            setFormState({...formState, Class: value})
                        }}
                        onDoneEditing={() => inputRefs[4].current?.focus()}
                    ></SingleSelectInput>
                    <ErrorRows errorRecord={errorState.Class}></ErrorRows>
                </Column>
                <Row height="10px"></Row>
                <Column>
                    <DatePicker
                        ref={inputRefs[4]}
                        placeholder="Birthday"
                        value={
                            new Date(formState.Birthday).toString() !== 'Invalid Date'
                                ? new Date(formState.Birthday)
                                : undefined
                        }
                        onChange={(value) => {
                            setFormState({...formState, Birthday: value ? formatDateYYYYMMDD(value) : ''})
                        }}
                        onDoneEditing={() => {
                            inputRefs[5].current?.focus()
                        }}
                    ></DatePicker>
                    <ErrorRows errorRecord={errorState.Birthday}></ErrorRows>
                </Column>
                <Row height="10px"></Row>
                <Column>
                    <VisibleInput
                        ref={inputRefs[5]}
                        type="text"
                        maxWidth="422px"
                        placeholder="Siblings' Names"
                        value={formState["Siblings' Names"] || ''}
                        scrollMarginBottom={110}
                        onChange={(ev) => {
                            hideError("Siblings' Names", errorState, setErrorState)
                            const newState = {...formState}
                            newState["Siblings' Names"] = ev.target.value
                            setFormState(newState)
                        }}
                        onKeyUp={(ev) => ev.key === 'Enter' && validateAndNext()}
                    ></VisibleInput>
                    <ErrorRows errorRecord={errorState["Siblings' Names"]}></ErrorRows>
                    <Row height="10px"></Row>
                    <Row>
                        <Column width="14px" grow={0} shrink={0}></Column>
                        <Column grow={0} shrink={1}>
                            <FieldSubText>e.g. Hannah &amp; Jack</FieldSubText>
                        </Column>
                        <Column width="14px" grow={1} shrink={0}></Column>
                    </Row>
                </Column>
            </Column>
        </Form>
    )
}

playground.push({
    path: 'src/components/CommunityBuild/PupilDetails.tsx',
    component: PupilDetails,
    props: {
        directoryName: 'Wetpups Grade 2',
        formFields: [
            {
                name: 'Grade',
                availableValues: ['Grade 2'],
                canCreateValue: false,
            },
            {name: 'Class', availableValues: ['Mr. Van Zyl'], canCreateValue: true},
        ],
        formState: {},
        errorState: {},
    },
    propOptions: {
        formState: {
            get: (props: any) => JSON.stringify(props.formState),
        },
        setFormState: ({props, args}: {props: any; args: any[]}) => ({...props, formState: args[0]}),
        errorState: {
            get: (props: any) => JSON.stringify(props.errorState),
        },
        setErrorState: ({props, args}: {props: any; args: any[]}) => ({...props, errorState: args[0]}),
        onNext: () => {},
        onBack: () => {},
    },
})
