import React, {useEffect, useState} from 'react'
import {
    useMutationRequestSelfBuildUserOtp,
    useMutationRequestStripeCustomerOtp,
    useMutationCreateStripeDirectory,
} from '~/apollo/queries/SelfBuild'
import {Token} from '../../TokenContext'
import {useMutationSignInOtp} from '~/apollo/queries/SignInOtp'
import {StripeFlow, Page, PageState} from './StripeFlow'

export const Stripe = ({customerId}: {customerId: string}) => {
    const [page, setPage] = useState<Page>('check-your-mail')
    const [pageState, setPageState] = useState<PageState>({
        errorState: {},
    })

    const [, callRequestOtp] = useMutationRequestSelfBuildUserOtp()
    const [, callRequestStripeCustomerOtp] = useMutationRequestStripeCustomerOtp()
    const [, callSignInOtp] = useMutationSignInOtp()
    const [, callCreateStripeDirectory] = useMutationCreateStripeDirectory()
    const {setToken} = Token.useContainer()

    useEffect(() => {
        callRequestStripeCustomerOtp({customerId}).then((result) => {
            setPageState({email: result.data?.requestStripeCustomerOTP || undefined, errorState: {}})
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customerId])

    return (
        <StripeFlow
            page={page}
            setPage={setPage}
            pageState={pageState}
            setPageState={setPageState}
            requestOtp={async (email: string) => {
                await callRequestOtp({email})
            }}
            signInOtp={async (otp: string, email: string) => {
                const result = await callSignInOtp({otp, email})
                result && setToken(result)
                return !!result
            }}
            onComplete={async (directoryName: string) => {
                const result = await callCreateStripeDirectory({
                    organisationName: directoryName,
                    customerId,
                    organisationToClone: 'Self Build Template',
                })
                return result.data?.createStripeDirectory
            }}
        />
    )
}
