import {uniq} from 'lodash'

export type FormFieldConfig = {name: string; availableValues: string[]; subType?: string | null}

export type FormState = Record<string, string>

export const mergeFormState = (state1: FormState, state2: FormState) => {
    return Object.fromEntries(
        uniq([...Object.keys(state1), ...Object.keys(state2)]).map((key) => [
            key,
            state2[key] !== undefined ? state2[key] : state1[key],
        ]),
    )
}
