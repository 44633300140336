import {Button} from 'nf-ui'
import SvgNamesAndFaces from 'nf-ui/Icons/NamesAndFaces'
import React, {FC, useEffect, useRef} from 'react'
import {Column, Row, scrollIfNeeded} from '../Layout'
import {Heading1, Heading3, ProgressBars1, ProgressBars2} from '..'
import {DeviceWidth} from '../Layout/types'

const Frame: FC<{
    scrollIntoView?: boolean
    frameWidth: string
    grow?: number
    shrink?: number
    minContentWidth?: string
    maxContentWidth?: string
    backgroundColor?: string
    boxShadow?: string
    borderRadius?: string
    deviceWidths?: DeviceWidth[] | undefined
}> = ({
    grow,
    shrink,
    scrollIntoView,
    frameWidth,
    maxContentWidth,
    minContentWidth,
    backgroundColor,
    boxShadow,
    borderRadius,
    deviceWidths,
    children,
}) => {
    const headingRow = useRef<HTMLDivElement>(null)

    useEffect(() => {
        if (scrollIntoView && headingRow.current)
            scrollIfNeeded(headingRow.current, {align: 'top', finishTimeoutMilliseconds: 0})
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [headingRow])

    return (
        <Column
            grow={grow}
            shrink={shrink}
            minHeight="100%"
            position="relative"
            ref={headingRow}
            backgroundColor={backgroundColor}
            borderRadius={borderRadius}
            boxShadow={boxShadow}
        >
            <Row grow={0} shrink={0} height={frameWidth} deviceWidths={deviceWidths}></Row>
            <Row grow={1} shrink={0} minHeight={`calc(100% - 2 * ${frameWidth} )`}>
                <Column
                    grow={maxContentWidth ? 1 : 0}
                    shrink={0}
                    minWidth={frameWidth}
                    deviceWidths={deviceWidths}
                ></Column>
                <Column grow={1} shrink={1} maxWidth={maxContentWidth} minWidth={minContentWidth}>
                    {children}
                </Column>
                <Column
                    grow={maxContentWidth ? 1 : 0}
                    shrink={0}
                    minWidth={frameWidth}
                    deviceWidths={deviceWidths}
                ></Column>
            </Row>
            <Row grow={0} shrink={0} height={frameWidth} deviceWidths={deviceWidths}></Row>
        </Column>
    )
}

const ContinueButton: FC<{disabled?: boolean; loading?: boolean; onClick: () => void}> = ({
    disabled,
    loading,
    onClick,
}) => (
    <Button
        style={{padding: '0px 15px', borderRadius: '3px', height: '40px', background: 'rgb(45, 113, 207)'}}
        disabled={disabled}
        loading={loading}
        onClick={onClick}
    >
        Continue&nbsp;&nbsp;&nbsp;&gt;
    </Button>
)

const FormRows: FC<{
    heading: string
    subHeading: string | string[]
    progress?: number | string
    loading?: boolean
    setLoading?: (value: boolean) => void
    onNext?: () => Promise<void>
}> = ({heading, subHeading, progress, loading, setLoading, onNext, children}) => {
    return (
        <>
            <Row grow={0} shrink={0} width="100%">
                <Column grow={0} shrink={0} centerVertical={true}>
                    <SvgNamesAndFaces width="170" height="36"></SvgNamesAndFaces>
                </Column>

                <Column grow={1} shrink={1}></Column>

                <Column grow={0} shrink={0} centerVertical={true} deviceWidths={['Desktop', 'Tablet']}>
                    {(typeof progress === 'string' ? parseInt(progress) > 0 : !!progress) && (
                        <ProgressBars2 progress={progress}></ProgressBars2>
                    )}
                </Column>

                <Column grow={1} shrink={1} deviceWidths={['Desktop', 'Tablet']}></Column>

                <Column grow={0} shrink={0} centerVertical={true} deviceWidths={['Desktop', 'Tablet']}>
                    <ContinueButton
                        disabled={!onNext}
                        loading={loading}
                        onClick={async () => {
                            if (onNext) {
                                await onNext()
                            }
                        }}
                    ></ContinueButton>
                </Column>
            </Row>

            <Row grow={0} shrink={0} height="48px" deviceWidths={['Desktop', 'Tablet']}></Row>
            <Row grow={0} shrink={0} height="20px" deviceWidths={['Phone', 'Small']}></Row>

            <Column grow={1} shrink={0}>
                <Row grow={0} shrink={0}>
                    <Heading1 fontSize="40px" fontWeight="400" lineHeight="56px" deviceWidths={['Desktop', 'Tablet']}>
                        {heading}
                    </Heading1>
                    <Heading1 fontSize="30px" fontWeight="400" lineHeight="42px" deviceWidths={['Phone', 'Small']}>
                        {heading}
                    </Heading1>
                </Row>
                <Row grow={0} shrink={0} height="10px"></Row>
                {Array.isArray(subHeading) ? (
                    subHeading.map((sh, index) => (
                        <Row grow={0} shrink={0} key={index} padding="0 0 0.8em 0">
                            <Heading3
                                fontSize="20px"
                                letterSpacing="-0.7px"
                                color="rgb(31, 31, 31)"
                                lineHeight="1.5em"
                                deviceWidths={['Desktop', 'Tablet']}
                            >
                                {sh}
                            </Heading3>
                            <Heading3
                                fontSize="15px"
                                letterSpacing="-0.7px"
                                color="rgb(31, 31, 31)"
                                lineHeight="1.125em"
                                deviceWidths={['Phone', 'Small']}
                            >
                                {sh}
                            </Heading3>
                        </Row>
                    ))
                ) : (
                    <Row grow={0} shrink={0}>
                        <Heading3
                            fontSize="20px"
                            letterSpacing="-0.7px"
                            color="rgb(31, 31, 31)"
                            lineHeight="1.5em"
                            deviceWidths={['Desktop', 'Tablet']}
                        >
                            {subHeading}
                        </Heading3>
                        <Heading3
                            fontSize="15px"
                            letterSpacing="-0.7px"
                            color="rgb(31, 31, 31)"
                            lineHeight="1.125em"
                            deviceWidths={['Phone', 'Small']}
                        >
                            {subHeading}
                        </Heading3>
                    </Row>
                )}
                <Row grow={0} shrink={0} height="30px" deviceWidths={['Desktop', 'Tablet']}></Row>
                <Row grow={0} shrink={0} height="15px" deviceWidths={['Phone', 'Small']}></Row>
                <Row grow={0} shrink={0}>
                    {children}
                </Row>
                <Row grow={1} shrink={1}></Row>
            </Column>

            {(typeof progress === 'string' ? parseInt(progress) > 0 : !!progress) && (
                <Column position="sticky" bottom="0px" backgroundColor="white" deviceWidths={['Phone', 'Small']}>
                    <Row grow={0} shrink={0} deviceWidths={['Phone', 'Small']} height="20px"></Row>
                    <Row grow={0} shrink={0} deviceWidths={['Phone', 'Small']}>
                        <Column grow={1}></Column>
                        <ContinueButton
                            disabled={!onNext}
                            loading={loading}
                            onClick={async () => {
                                if (onNext) {
                                    await onNext()
                                }
                            }}
                        ></ContinueButton>
                    </Row>
                    <Row grow={0} shrink={0} height="20px"></Row>
                    <Row grow={0} shrink={0}>
                        <ProgressBars1 progress={progress} max={5}></ProgressBars1>
                    </Row>
                    <Row grow={0} shrink={0} height="20px"></Row>
                </Column>
            )}
        </>
    )
}

export const FormLayout2: FC<{
    heading: string
    subHeading: string | string[]
    progress?: number | string
    loading?: boolean
    setLoading?: (value: boolean) => void
    onNext?: () => Promise<void>
}> = ({heading, subHeading, progress, loading, setLoading, onNext, children}) => {
    return (
        <Frame
            scrollIntoView={true}
            frameWidth="48px"
            backgroundColor="rgb(244,246,248)"
            maxContentWidth="960px"
            deviceWidths={['Desktop', 'Tablet', 'Phone']}
        >
            <Frame frameWidth="48px" backgroundColor="white" borderRadius="5px" boxShadow="0 1px 2px #00000040">
                <FormRows
                    heading={heading}
                    subHeading={subHeading}
                    progress={progress}
                    loading={loading}
                    setLoading={setLoading}
                    onNext={onNext}
                >
                    {children}
                </FormRows>
            </Frame>
        </Frame>
    )
}
