import {Button} from 'nf-ui'
import SvgNamesAndFaces from 'nf-ui/Icons/NamesAndFaces'
import React, {FC, ReactNode, useEffect, useRef, useState} from 'react'
import {Column, Row, scrollIfNeeded} from '../Layout'
import {Heading1, Heading3, ProgressBars1, Questions} from '..'

const QuestionsPanel: FC<{onClose: () => void}> = ({onClose, children}) => (
    <Column backgroundColor="rgba(0,0,0,0.8)" width="100%" height="100%">
        <Row grow={0} shrink={0} height="20px"></Row>
        <Row grow={0} shrink={0} height="calc(100% - 60px)">
            <Column grow={0} shrink={0} width="30px"></Column>
            <Column
                grow={0}
                shrink={0}
                width="calc(100% - 60px)"
                maxWidth="880px"
                borderRadius="5px"
                backgroundColor="white"
            >
                <Row grow={0} shrink={0} height="100%" overflow="auto">
                    <Column grow={0} shrink={0} width="40px"></Column>
                    <Column grow={1} shrink={1} height="100%">
                        <Row grow={0} shrink={0} position="sticky" top="0px" backgroundColor="white">
                            <Column grow={1}>
                                <Row grow={0} height="40px"></Row>
                                <Row grow={1}>
                                    <Column shrink={1} deviceWidths={['Desktop', 'Tablet']}>
                                        <SvgNamesAndFaces width="170" height="36"></SvgNamesAndFaces>
                                    </Column>
                                    <Column shrink={1} deviceWidths={['Phone', 'Small']} maxWidth="calc(100% - 107px)">
                                        <SvgNamesAndFaces width="143" height="30"></SvgNamesAndFaces>
                                    </Column>
                                    <Column
                                        onClick={onClose}
                                        cursor="pointer"
                                        style={{
                                            fontSize: '50px',
                                            lineHeight: '35px',
                                            transform: 'rotate(45deg)',
                                            transformOrigin: '50% 50% 0px',
                                        }}
                                    >
                                        +
                                    </Column>
                                </Row>
                                <Row grow={0} height="10px"></Row>
                            </Column>
                        </Row>
                        <Row grow={0} shrink={0}>
                            {children}
                        </Row>
                        <Row grow={1}></Row>
                    </Column>
                    <Column grow={0} shrink={1} width="40px"></Column>
                </Row>
            </Column>
            <Column grow={0} shrink={0} width="30px"></Column>
        </Row>
        <Row grow={0} shrink={0} height="40px"></Row>
    </Column>
)

export const FormLayout1: FC<{
    heading: string
    subHeading: string | string[]
    progress?: number | string
    onNext?: () => Promise<void>
    onBack?: () => void
    questionsPanelContent?: ReactNode
}> = ({heading, subHeading, progress, onNext, onBack, questionsPanelContent, children}) => {
    const [loading, setLoading] = useState<boolean>(false)
    const [questionsPanelOpen, setQuestionsPanelOpen] = useState<boolean>(false)
    const headingRow = useRef<HTMLDivElement>(null)

    useEffect(() => {
        if (headingRow.current) scrollIfNeeded(headingRow.current, {align: 'top', finishTimeoutMilliseconds: 0})
    }, [headingRow])

    return (
        <Row width="100%" height="100%" position="relative" ref={headingRow}>
            <Column width="160px" grow={0} shrink={0} deviceWidths={['Desktop']}></Column>
            <Column width="100px" grow={0} shrink={0} deviceWidths={['Tablet']}></Column>
            <Column width="25px" grow={0} shrink={0} deviceWidths={['Phone', 'Small']}></Column>
            <Column grow={1} shrink={1} height="100%" maxWidth="min(880px, calc(100% - 50px))">
                <Row grow={0} shrink={0} height="49px" deviceWidths={['Desktop']}></Row>
                <Row grow={0} shrink={0} height="45px" deviceWidths={['Tablet']}></Row>
                <Row grow={0} shrink={0} height="25px" deviceWidths={['Phone', 'Small']}></Row>
                <Row grow={0} shrink={0}>
                    <Column shrink={1} deviceWidths={['Desktop', 'Tablet']}>
                        <SvgNamesAndFaces width="170" height="36"></SvgNamesAndFaces>
                    </Column>
                    <Column shrink={1} deviceWidths={['Phone', 'Small']} maxWidth="calc(100% - 107px)">
                        <SvgNamesAndFaces width="143" height="30"></SvgNamesAndFaces>
                    </Column>
                    <Column shrink={0} deviceWidths={['Desktop']}>
                        {questionsPanelContent && (
                            <Questions fontSize="14px" onClick={() => setQuestionsPanelOpen(true)}>
                                Questions?
                            </Questions>
                        )}
                    </Column>
                    <Column shrink={0} deviceWidths={['Tablet', 'Phone', 'Small']} backgroundColor="white">
                        {questionsPanelContent && (
                            <Questions fontSize="12px" onClick={() => setQuestionsPanelOpen(true)}>
                                Questions?
                            </Questions>
                        )}
                    </Column>
                </Row>
                <Row grow={0} shrink={0} height="44px" deviceWidths={['Desktop', 'Tablet']}></Row>
                <Row grow={0} shrink={0} height="25px" deviceWidths={['Phone', 'Small']}></Row>
                <Column grow={1} shrink={0}>
                    <Row grow={0} shrink={0} height="31px" deviceWidths={['Desktop', 'Tablet']}></Row>
                    <Row grow={0} shrink={0} height="35px" deviceWidths={['Phone', 'Small']}></Row>
                    <Row grow={0} shrink={0}>
                        <Heading1 fontSize="88px" deviceWidths={['Desktop']}>
                            {heading}
                        </Heading1>
                        <Heading1 fontSize="72px" deviceWidths={['Tablet']}>
                            {heading}
                        </Heading1>
                        <Heading1 fontSize="48px" deviceWidths={['Phone', 'Small']}>
                            {heading}
                        </Heading1>
                    </Row>
                    <Row grow={0} shrink={0} height="15px" deviceWidths={['Desktop', 'Tablet']}></Row>
                    <Row grow={0} shrink={0} height="10px" deviceWidths={['Phone', 'Small']}></Row>
                    {Array.isArray(subHeading) ? (
                        subHeading.map((sh, index) => (
                            <Row grow={0} shrink={0} key={index} padding="0 0 0.8em 0">
                                <Heading3 fontSize="20px" letterSpacing="-0.4px" deviceWidths={['Desktop', 'Tablet']}>
                                    {sh}
                                </Heading3>
                                <Heading3 fontSize="18px" letterSpacing="-0.2px" deviceWidths={['Phone', 'Small']}>
                                    {sh}
                                </Heading3>
                            </Row>
                        ))
                    ) : (
                        <Row grow={0} shrink={0}>
                            <Heading3 fontSize="20px" letterSpacing="-0.4px" deviceWidths={['Desktop', 'Tablet']}>
                                {subHeading}
                            </Heading3>
                            <Heading3 fontSize="18px" letterSpacing="-0.2px" deviceWidths={['Phone', 'Small']}>
                                {subHeading}
                            </Heading3>
                        </Row>
                    )}
                    <Row grow={0} shrink={0} height="30px"></Row>
                    <Row grow={0} shrink={0}>
                        {children}
                    </Row>
                    {(typeof progress === 'string' ? parseInt(progress) > 0 : !!progress) && (
                        <>
                            <Row grow={0} shrink={0} height="40px" deviceWidths={['Desktop', 'Tablet']}></Row>
                            <Row grow={0} shrink={0} height="20px" deviceWidths={['Phone', 'Small']}></Row>
                        </>
                    )}
                    <Row grow={1} shrink={1}></Row>
                </Column>
                {(typeof progress === 'string' ? parseInt(progress) > 0 : !!progress) && (
                    <Column position="sticky" bottom="0px" backgroundColor="white">
                        <Row grow={0} shrink={0} height="20px"></Row>
                        <Row grow={0} shrink={0}>
                            <ProgressBars1 progress={progress} max={3}></ProgressBars1>
                        </Row>
                        <Row grow={0} shrink={0} height="20px"></Row>
                        <Row grow={0} shrink={0} deviceWidths={['Desktop', 'Tablet']}>
                            <Button
                                variant="tertiary"
                                style={{padding: '0px 36px', borderRadius: '5px', height: '44px'}}
                                onClick={() => onBack?.()}
                            >
                                Back
                            </Button>
                            <Button
                                style={{padding: '0px 36px', borderRadius: '5px', height: '44px'}}
                                disabled={!onNext}
                                loading={loading}
                                onClick={async () => {
                                    if (onNext) {
                                        setLoading(true)
                                        await onNext()
                                        setLoading(false)
                                    }
                                }}
                            >
                                Next
                            </Button>
                        </Row>
                        <Row grow={0} shrink={0} deviceWidths={['Phone', 'Small']}>
                            <Button
                                variant="tertiary"
                                style={{padding: '0px 24px', borderRadius: '5px', height: '44px'}}
                                onClick={() => onBack?.()}
                            >
                                Back
                            </Button>
                            <Button
                                style={{padding: '0px 24px', borderRadius: '5px', height: '44px'}}
                                disabled={!onNext}
                                loading={loading}
                                onClick={async () => {
                                    if (onNext) {
                                        setLoading(true)
                                        await onNext()
                                        setLoading(false)
                                    }
                                }}
                            >
                                Next
                            </Button>
                        </Row>
                        <Row grow={0} height="20px"></Row>
                    </Column>
                )}
            </Column>
            <Column width="160px" grow={0} shrink={0} deviceWidths={['Desktop']}></Column>
            <Column width="100px" grow={0} shrink={0} deviceWidths={['Tablet']}></Column>
            <Column width="25px" grow={0} shrink={0} deviceWidths={['Phone', 'Small']}></Column>
            {questionsPanelContent && questionsPanelOpen ? (
                <Column position="fixed" top="0px" right="0px" bottom="0px" left="0px">
                    <QuestionsPanel onClose={() => setQuestionsPanelOpen(false)}>
                        {questionsPanelContent}
                    </QuestionsPanel>
                </Column>
            ) : (
                <></>
            )}
        </Row>
    )
}
